.jenkins-tooltip {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px;
  background: var(--tooltip-background-color);
  box-shadow: var(--tooltip-shadow);
  color: var(--tooltip-foreground-color);
  font-size: 0.8rem;
  z-index: 40;
  overflow: hidden;
}
