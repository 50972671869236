.jenkins-app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .jenkins-app-bar__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 36px;
  }

  .jenkins-app-bar__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    min-height: 36px;

    .jenkins-search {
      min-width: 260px;
    }
  }

  &--border {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid var(--panel-border-color);
  }

  h1 {
    margin: 0;
    font-size: 1.7rem;
  }
}
