.app-about-branding {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #28404e;
  padding: 2rem;
  pointer-events: none;

  @media (max-width: 970px) {
    height: 200px;
  }

  .app-about-branding__logo {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 970px) {
      position: relative;
    }

    img {
      height: 12.5vh;
      margin-right: 1.5rem;
    }

    h1 {
      display: flex;
      flex-direction: column;
      font-family: "Georgia", serif;
      color: white;
      text-shadow: 0 2px 0 #1b1819;

      span {
        margin-top: 10px;
        font-family: var(--font-family-sans);
        font-size: 0.7rem;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        opacity: 0.85;
      }
    }
  }
}
